/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Hero from 'components/hero';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import AnnouncementSection from 'components/annoucement';


/*
 *
 */

export default function QRCodeMenu () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.qr-code-menu.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.qr-code-menu.meta_desc' })} />
				<meta property="og:image" content={intl.formatMessage({ id: 'global.meta.og_image.qr-code-menu' })} />
				<meta property="og:url" content={intl.formatMessage({ id: 'global.meta.og_url.qr-code-menu' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.qr-code-menu.hero.title' })}
					subtitle={intl.formatMessage({ id: 'page.qr-code-menu.hero.subtitle' })}
				/>
			</Section>
			<AnnouncementSection>
				<p>{intl.formatMessage({ id: 'page.qr-code-menu.annoucement.payconiq'})}<a href={intl.formatMessage({ id: 'page.qr-code-menu.annoucement.payconiq.ctaLink' })}>{intl.formatMessage({ id: 'page.qr-code-menu.annoucement.payconiq.ctaText'})}</a></p>
			</AnnouncementSection>
			<Section type="no-padding">
				<iframe 
					title={intl.formatMessage({ id: 'page.qr-code-menu.hero.title' })}
					src={intl.formatMessage({ id: 'page.qr-code-menu.google_form' })} 
					width="100%" 
					height="3282" 
					frameBorder="0" 
					marginHeight="0" 
					marginWidth="0">Loading…</iframe>
			</Section>
		</React.Fragment>
	);
}