import React from 'react';
import styled from 'styled-components';

/*
 *
 */

const Section = styled.div`
	background-color:#3d9f41;
	color:white;
    padding: 1rem 3rem;
    font-weight: 500;
    text-align:center;
    font-size: 1.5rem;

    @media (${props => props.theme.media.tablet}) {
		font-size: 1.75rem;
	}

    p {
	   	display: flex;
	    flex-direction: column;
	    
	    @media (${props => props.theme.media.tablet}) {
			display:inline-block;
		}
	}

	a {
		background: #fff;
	    padding: 0.5rem 2rem;
	    margin-left: 1rem;
	    color: #3d9f41;
		text-decoration: none;
		font-weight: 500;
		transition: 0.2s;
		border:1px solid #3d9f41;
		transition:0.2s;
		border-radius:2rem;
		margin-top:1rem;

		@media (${props => props.theme.media.tablet}) {
			margin-top:Orem;	
		}
	
		&:hover {
		    border-color:#fff;
	    }
	}
`;


export default function AnnouncementSection ({ children }) {

	return (
		<Section>
			{children}
		</Section>
	);
}
